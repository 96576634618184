<template>
  <div class="container-fluid vh-100 responsive-container d-flex justify-content-center align-items-center">
    <div class="row w-100">
      <div class="col-md-6" data-aos="fade-up-right">
        <b-embed
          type="iframe"
          aspect="16by9"
          src="https://player.vimeo.com/video/497724921?autoplay=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0#t="
          allowfullscreen
        ></b-embed>
      </div>
      <div class="col-md-6">
        <div class="h-100 mt-4 mt-md-0 d-flex flex-column justify-content-center px-md-5" data-aos="fade-up-left">
          <h1 class="title">TRENDING<br>TECHNOLOGY</h1>
          <div class="mt-4">
            <p class="copy mb-5">
              In 2013 our scientist and CEO, Agostino Sibillo, invented a new method
              to share objects and messages in Augmented Reality.
              Patent number .9,940,477
            </p>
            <a href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO2&Sect2=HITOFF&p=1&u=%2Fnetahtml%2FPTO%2Fsearch-bool.html&r=1&f=G&l=50&co1=AND&d=PTXT&s1=%22Agostino+sibillo%22&OS=" class="link-to-patent">
              Link to patent >
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
@media (max-width: 768px) {
  img {
    max-height: 400px !important;
  }

  .title {
    font-size: 2.5rem;
  }
}
</style>