<template>
  <div class="container-fluid px-0 vh-100 responsive-container d-flex justify-content-center align-items-center">
    <div class="row w-100 container-video">
      <div class="overlay"></div>
      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/playback.mp4?alt=media&token=ab8ead91-5150-4ab2-82f0-234c457dc37b" type="video/mp4">
      </video>
      <div class="container h-100">
        <div class="d-flex h-100 text-center justify-content-end align-items-center">
          <div class="w-100 h-100 text-white">
            <div class="row h-100">
              <div class="col-md-12 h-100">
                <div class="d-flex flex-column h-100 justify-content-end">
                  <h1 class="title">
                    LIVEOFME INC.
                  </h1>
                  <p class="my-3 font-weight-bold">IN FACT OF MANAGING AND PROTECTING DATA</p>
                  <p class="copy">
                    In 2012 Liveofme inc., subsidiary of Spychatter Inc., <br>
                    made the first system that enables people to create live video <br>
                    sessions and share them with an unlimited number of users.
                  </p>
                  <div class="text-center">
                    <img src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/livephone.png?alt=media&token=10c8db22-8317-4400-b7c5-3d0bc57c83a3" alt="" width="700">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.container-video {
  position: relative;
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.container-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.container-video .container {
  position: relative;
  z-index: 2;
}

.container-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

@media (max-width: 768px) {
  img {
    max-width: 280px !important;
  }
}
</style>