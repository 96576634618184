<template>
  <div class="container-fluid px-5 mt-5">

    <p class="text-center mb-5 pb-5 copy">
      Copyright 2020 | Spychatter Inc. | 601S. Figueroa St. Los Angeles, Ca, 90017
    </p>

  </div>
</template>

<script>
export default {
}
</script>
