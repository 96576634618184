<template>
  <div class="container-fluid px-0 text-center text-md-left">
    <section-one/>
    <section-two/>
    <section-three/>
    <section-four/>
    <section-five/>
    <section-six/>
    <section-seven/>
    <section-eight/>
    <section-nine/>
  </div>
</template>

<script>
import SectionOne from '@/components/SectionOne.vue'
import SectionTwo from '@/components/SectionTwo.vue'
import SectionThree from '@/components/SectionThree.vue'
import SectionFour from '@/components/SectionFour.vue'
import SectionFive from '@/components/SectionFive.vue'
import SectionSix from '@/components/SectionSix.vue'
import SectionSeven from '@/components/SectionSeven.vue'
import SectionEight from '@/components/SectionEight.vue'
import SectionNine from '@/components/SectionNine';


export default {
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
    SectionEight,
    SectionNine
  },
}
</script>
