import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/custom.scss';
import App from './App.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Vuelidate from 'vuelidate';
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuesax);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  created() {
    AOS.init({ duration: 2000 });
  }
}).$mount('#app')
