<template>
  <div class="container-fluid px-0 portada vh-100 responsive-container d-flex">
    <div class="row w-100 overlay">
      <div class="col-md-6 mb-5 pl-md-5">
        <div class="h-100 d-flex flex-column justify-content-end pb-5" data-aos="fade-down-right">
          <h1 class="title">SPACENET INC.</h1>
          <div class="my-4 pl-md-5">
            <p class="copy text-left">
              In 2018 SPYCHATTER Inc. Holding Incorporated<br>
              a new subsidiary: SPACENET Inc. An important goal of this company is to create new ways to share data.
            </p>
            <p class="copy mb-5 text-left">
              In 2019 Spacenet filed a new patent that developed <br>
              a new and innovative method of sharing data using a Laser.
            </p>
            <a href="https://uspto.report/patent/app/20200314069" target="_blank" class="link-to-patent">
              Link to patent >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
}
</style>
