<template>
  <div class="container-fluid px-0 vh-100 responsive-container d-flex justify-content-center align-items-center">
    <div class="row w-100">
      <div class="col-md-6">
        <div class="h-100 mt-4 mt-md-0 d-flex flex-column justify-content-center px-md-5 text-center" data-aos="fade-up-left">
          <i class="fas fa-lock lock"></i>
          <p class="title mt-3">
            THE IMPORTANCE CYBER SECURITY
          </p>
          <p class="font-weight-bold sub mt-5">
            DATA PROTECTION AND SAFE DATA SHARING IS OUR MISSION!
          </p>
          <p class="tag">
            Our business model is focused on licensing our technology and on creating softwares and apps for governments and private organizations.
          </p>
          
        </div>
      </div>

      <div class="col-md-6 px-0" data-aos="fade-up-right">
        <div class="video-background w-100">
          <b-embed
          type="iframe"
          aspect="16by9"
          autoplay
          src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/typing.mp4?alt=media&token=b14695ba-2dbf-4107-af08-578298102aeb"
          allowfullscreen
        ></b-embed>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
@media (max-width: 768px) {
  img {
    max-height: 400px !important;
  }
}

.title {
  font-size: 2rem;
  letter-spacing: .1rem;
}

.tag {
  font-size: 1.2rem;
}

.sub {
  font-size: 1.4rem;
}

.lock {
  font-size: 4.5rem;
}

</style>