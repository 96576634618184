<template>
  <div id="app" class="container-fluid px-0 overflow-hidden">
    <content-page/>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import ContentPage from '@/components/ContentPage.vue'
import CustomFooter from './components/CustomFooter.vue'

export default {
  components: { ContentPage, CustomFooter }
}
</script>
