<template>
  <div class="container vh-100 responsive-container d-flex flex-column justify-content-center w-100" id="section-two">
    <h3 class="title">CONTACT US</h3>

    <form ref="form" method="POST" @submit="sendForm">
      <div class="row w-100 mt-5 mb-3">
        <div class="col-md-6" data-aos="fade-right">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': $v.name.$error }"
              placeholder="First Name"
              v-model.trim="$v.name.$model"
            />
            <div v-if="$v.name.$error" class="invalid-feedback">
              The name field is required
            </div>
          </div>
        </div>
        <div class="col-md-6" data-aos="fade-left">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Last Name"
              :class="{ 'is-invalid': $v.lastname.$error }"
              v-model.trim="$v.lastname.$model"
            />
            <div v-if="$v.name.$error" class="invalid-feedback">
              The last name field is required
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100 mb-3">
        <div class="col-md-6" data-aos="fade-right">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Company Name"
              :class="{ 'is-invalid': $v.company.$error }"
              v-model.trim="$v.company.$model"
            />
            <div v-if="$v.name.$error" class="invalid-feedback">
              The company field is required
            </div>
          </div>
        </div>
        <div class="col-md-6" data-aos="fade-left">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Phone"
              :class="{ 'is-invalid': $v.phone.$error }"
              v-model.trim="$v.phone.$model"
            />
            <div v-if="$v.name.$error" class="invalid-feedback">
              <span v-if="!$v.phone.required">The phone field is required</span>
              <span v-if="!$v.phone.numeric"
                >The phone field must be numeric</span
              >
              <span v-if="!$v.phone.minLength && $v.phone.numeric"
                >The phone field must be 10 numbers</span
              >
              <span v-if="!$v.phone.maxLength && $v.phone.numeric"
                >The phone field must be 10 numbers</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row w-100 mb-3">
        <div class="col-md-6" data-aos="fade-right">
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              :class="{ 'is-invalid': $v.email.$error }"
              v-model.trim="$v.email.$model"
            />
            <div v-if="$v.name.$error" class="invalid-feedback">
              <span v-if="!$v.email.required">The email field is required</span>
              <span v-if="!$v.email.email"
                >The email field must be a valid email</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row w-100">
        <div class="col-md-12" data-aos="fade-right">
          <div class="form-group">
            <textarea
              class="form-control"
              rows="3"
              placeholder="Message"
              :class="{ 'is-invalid': $v.message.$error }"
              v-model.trim="$v.message.$model"
            ></textarea>
            <div v-if="$v.name.$error" class="invalid-feedback">
              The message field is required
            </div>
          </div>
        </div>
      </div>

      <button class="link-to-patent mt-4">Submit</button>
    </form>
  </div>
</template>

<script>
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  validations: {
    name: {
      required,
    },
    lastname: {
      required,
    },
    company: {
      required,
    },
    phone: {
      required,
      numeric,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    email: {
      required,
      email,
    },
    message: {
      required,
    },
  },

  data() {
    return {
      name: "",
      lastname: "",
      company: "",
      phone: "",
      email: "",
      message: "",
    };
  },

  methods: {
    async sendForm(e) {
      e.preventDefault();
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$vs.loading();

        let data = new FormData();
        data.append('name', this.name);
        data.append('lastname', this.lastname);
        data.append('company', this.company);
        data.append('phone', this.phone);
        data.append('email', this.email);
        data.append('message', this.message);
        await axios.post("https://formspree.io/f/myylgpoo", data);

        this.$vs.loading.close();

        this.$vs.notify({
          title: "Message sent",
          text: "We will contact you shortly",
          time: 8000,
          buttonClose: true,
        });

        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
input,
textarea {
  background: transparent;
  color: white;
  border-radius: 1px;
  border-color: #eaeaea;
  padding: 0.75em;
}

input {
  height: 2.8em;
}

button {
  border-color: transparent;
}

.title {
  font-size: 2rem;
}
</style>
