<template>
  <div class="vh-100">
    <div class="d-flex justify-content-center">
      
    </div>
    <div class="h-100 d-flex flex-column justify-content-center align-items-center">
      <div class="justify-content-center align-items-center" data-aos="zoom-in-down">
        <!-- <img src="https://media0.giphy.com/media/TKw34hTizVK9bHDUIc/giphy.gif" class="img-fluid" width="120" alt="test_gift"> -->
        <div class="d-flex justify-content-center align-items-center">
          <p class="p-0 m-0 mt-2" style="font-size: 18px">In partership with</p>
          <img src="@/assets/images/aws.png" alt="" width="130px" class="mx-2 mt-2">
        </div>
        <img src="@/assets/images/logo.png" alt="logo" style="max-width: 110%" class="px-5 mt-5">
      </div>

    </div>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

.img-fluid {
  max-width: 85% !important;
}

.movil-cover {
  position: static;
  min-width: 0;
}

@media (max-width: 768px) {

  .font-size-3xl {
    font-size: 2.8rem !important;
  }

  .vh-100 {
    height: 95vh !important;
  }

  .movil-cover {
    position: relative;
    bottom: 35px;
    min-width: 470px;
    left: 30px;
  }
}

.heartbeat {
	animation: heartbeat 6s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  9% {
    transform: scale(1.09);
    animation-timing-function: ease-in;
  }
  16% {
    transform: scale(1.02);
    animation-timing-function: ease-out;
  }
  29% {
    transform: scale(1.13);
    animation-timing-function: ease-in;
  }
  43% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}



</style>
