<template>
  <div class="container-fluid vh-100 responsive-container d-flex justify-content-center align-items-center" id="section-two">
    <div class="row w-100">
      <div class="col-md-6">
        <div class="h-100 d-flex flex-column justify-content-center px-md-5" data-aos="fade-down">
          <h1 class="title">AUGMENTED<br>REALITY</h1>
          <div class="mt-4">
            <p class="copy mb-5">
              We hold the most important patent for sharing of augmented reality. <br>
              SpyChatter thanks to his patent, controls and owns the sector of sharing
              Augmented Reality with a company’s evaluation of more than 130 Billion dollars.
            </p>
            <a href="https://uspto.report/patent/app/20190197252#:~:text=Agostino%20Sibillo" target="_blank" class="link-to-patent mt-4">
              Link to patent >
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6"  data-aos="fade-left">
        <b-embed
          class="mt-5 mt-md-0"
          type="iframe"
          aspect="16by9"
          src="https://player.vimeo.com/video/497384284?autoplay=1&amp;playsinline=1&amp;color&amp;autopause=0&amp;loop=1&amp;muted=0&amp;title=0&amp;portrait=0&amp;byline=0#t="
          allowfullscreen
        ></b-embed>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
@media (max-width: 768px) {
  img {
    max-height: 400px !important;
  }

  .title {
    font-size: 2.5rem;
  }
}
</style>
