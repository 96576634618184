<template>
  <div class="container-fluid px-0 vh-100 responsive-container d-flex justify-content-center align-items-center">
    <div class="row w-100 container-video">
      <div class="overlay"></div>
      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/fondo.mp4?alt=media&token=4598189e-cc4a-406a-a55d-5980435236aa" type="video/mp4">
      </video>
      <div class="container h-100">
        <div class="d-flex h-100 text-center justify-content-end align-items-center">
          <div class="w-100 text-white">
            <div class="row">
              <div class="col-md-12">
                <p class="font-weight-bold" style="line-height: 1.1">
                  WE ARE TRUE LEADERS IN ENCRYPTION THAT UTILIZE THE GPS, GYROSCOPE, COMPASS, AND THE ACCELEROMETER
                </p>

                <p class="company mt-5 spacing-letter">SPYCHATTER INC.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.container-video {
  position: relative;
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.container-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.container-video .container {
  position: relative;
  z-index: 2;
}

.container-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

p {
  font-size: 2rem;
}

.company {
  font-size: 1.2rem;
}
</style>