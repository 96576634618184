<template>
  <div class="container-fluid px-0 vh-100 responsive-container d-flex justify-content-center align-items-center">
    <div class="row w-100 container-video">
      <div class="overlay"></div>
      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
        <source src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/doctors.mp4?alt=media&token=a7ce44e0-5248-4fc6-897d-423e97c5c677" type="video/mp4">
      </video>
      <div class="container h-100">
        <div class="d-flex h-100 text-center justify-content-end align-items-center">
          <div class="w-100 text-white">
            <div class="row">
              <div class="col-md-6">
                <p class="font-weight-bold fon-size-xl" style="line-height: 1.1">
                  Digital Healthcare<br>
                  Holistic Solutions
                </p>
                <img src="https://firebasestorage.googleapis.com/v0/b/spychatterar.appspot.com/o/videocall.png?alt=media&token=2ba18001-6346-4457-affa-3db264f9ea21" alt="">
              </div>
              <div class="col-md-6">
                <div class="d-flex flex-column h-100 pb-3 pb-md-5 justify-content-end">
                  <h1 class="title text-left mt-2 mt-md-5">THANKS TO YEARS<br>OF EXPERIENCE</h1>
                  <p class="text-left my-3 font-weight-bold">IN FACT OF MANAGING AND PROTECTING DATA</p>
                  <p class="copy text-left">
                    we are today one of the most important company making advanced software products for healthcare.
                    Ihealthapp is our latest fully integrated digital system for healthcare.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
}

.container-video {
  position: relative;
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.container-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.container-video .container {
  position: relative;
  z-index: 2;
}

.container-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

@media (max-width: 768px) {
  img {
    max-height: 340px !important;
  }
}

</style>